@import url(fontawesome-all.min.css);
@import url("https://fonts.googleapis.com/css?family=Merriweather:300,700,300italic,700italic|Source+Sans+Pro:900|Playfair+Display:400,400italic,500,500italic,600,600italic,700,700italic|Material+Icons|Roboto:300,400,500");

body {
  margin: 0;
	/* background-color: #eee; */
	background-color: #f5d8d7;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  color: #212931;
}

body, input, select, textarea {
  font-family: "Merriweather", Georgia, serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 2.375;
}
.terminal-style {
  display: block;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
}
.component-container {
  margin-top: 40px
}


	a {
		-moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		-webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		-ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		text-decoration: none;
    cursor: pointer;
	}

		a:hover {
			border-bottom-color: transparent;
		}

	strong, b {
		font-weight: 600;
	}

	em, i {
		font-style: italic;
	}

	p {
		text-align: justify;
		margin: 0 0 2rem 0;
	}

	h1, h3, h4, h5, h6 {
		font-family: "Source Sans Pro", Helvetica, sans-serif;
		font-weight: 900;
		line-height: 1.5;
		letter-spacing: 0.075em;
		text-transform: uppercase;
		margin: 0 0 1rem 0;
	}

	h2 {
		font-family: 'Playfair Display';
		font-size: 2.75rem;
		font-weight: bold;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			border-bottom: 0;
			color: inherit;
			text-decoration: none;
		}

	h1 {
		font-size: 4rem;
		line-height: 1.1;
		margin: 0 0 2rem 0;
	}

	h2 {
		/* font-size: 1.75rem; */
		line-height: 1.3;
		margin: 0 0 1.5rem 0;
	}

	h3 {
		font-family: 'Playfair Display';
		font-size: 1.5rem;
		font-weight: bold;
		text-transform: none;
		letter-spacing: normal;
		margin: 1.5rem 0 1.5rem 0;
	}

	h4 {
		font-size: 1rem;
	}

	h5 {
		font-size: 0.9rem;
	}

	h6 {
		font-size: 0.8rem;
	}

	sub {
		font-size: 0.8rem;
		position: relative;
		top: 0.5rem;
	}

	sup {
		font-size: 0.8rem;
		position: relative;
		top: -0.5rem;
	}

	blockquote {
		border-left: solid 4px;
		font-style: italic;
		margin: 0 0 2rem 0;
		padding: 0.5rem 0 0.5rem 2rem;
	}

	code {
		border: solid 2px;
		font-family: "Courier New", monospace;
		font-size: 0.9rem;
		margin: 0 0.25rem;
		padding: 0.25rem 0.65rem;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9rem;
		margin: 0 0 2rem 0;
	}

		pre code {
			display: block;
			line-height: 1.75;
			padding: 1rem 1.5rem;
			overflow-x: auto;
		}

	hr {
		border: 0;
		border-bottom: solid 2px;
		margin: 3rem 0;
	}

		hr.major {
			margin: 5rem 0;
		}

#app-bar button {
  box-shadow: none !important;
  color: inherit !important;
}

#admin-layout {
  /* background-color: white; */
  color: black;
}
.MuiButton-containedPrimary {
  color: white !important;
}
.article-title-label {
  position: absolute;
  font-size: 40px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 15px;
  color: grey;
}
textarea#article-title {
    padding:0;
    margin: 0;
    font-size:50px;
    font-weight:bold;
    border: none;
}
/* .MuiAlert-action {
  display: none !important;
} */
.MuiButtonBase-root,
button {
  box-shadow: none;
}

p.bullet-note::before {
    content: "[NOTE] "
}
p.bullet-task::before {
    content: "[TASK] "
}
p.bullet-mood::before {
    content: "[MOOD] "
}
p.bullet-event::before {
    content: "[EVENT] "
}
p.bullet-habit::before {
    content: "[HABIT] "
}
span.bulletDate, span.transactionDate {
    font-size: 10px;
    display: block;
}
.transactionDate {
	text-align: right;
}
span.bulletIcon, span.transactionIcon {
    margin-top: 6px;
    position: absolute;
}
span.bulletText, .transactionText {
  margin-left: 30px;
}
.transactionText {
	font-size: 14px;
}
.transactionAmount {
	padding-right: 40px;
	font-size: 20px;
	text-align: right;
}
.habit-add {
  color: #2ca5e0;
  position: absolute;
  margin-top: 7px;
  margin-left: 5px;
  cursor: pointer;
}
.bulletActions, .transactionActions {
  text-align: right;
  margin-top: -50px;
  cursor: pointer;
  -webkit-transition: color 0.5s; /* For Safari 3.0 to 6.0 */
  transition: color 0.5s; /* For modern browsers */
}

.bulletActionDeleteIcon, .transactionActionDeleteIcon {
  color: #ead4d4;
  -webkit-transition: color 0.5s; /* For Safari 3.0 to 6.0 */
  transition: color 0.5s; /* For modern browsers */
}

.bulletActionDeleteIcon:hover, .transactionActionDeleteIcon:hover {
  color: red;
}

.bulletActionEditIcon, .transactionActionEditIcon {
  color: #ead4d4;
  -webkit-transition: color 0.5s; /* For Safari 3.0 to 6.0 */
  transition: color 0.5s; /* For modern browsers */
}

.bulletActionEditIcon:hover, .transactionActionEditIcon:hover {
  color: red;
}

.taskActions {
	float: right;
	text-align: right;
	margin-top: -50px;
	cursor: pointer;
	-webkit-transition: color 0.5s; /* For Safari 3.0 to 6.0 */
	transition: color 0.5s; /* For modern browsers */
}

.taskActionDeleteIcon {
color: #ead4d4;
-webkit-transition: color 0.5s; /* For Safari 3.0 to 6.0 */
transition: color 0.5s; /* For modern browsers */
}

.taskActionDeleteIcon:hover {
color: red;
}

.taskActionEditIcon {
color: #ead4d4;
-webkit-transition: color 0.5s; /* For Safari 3.0 to 6.0 */
transition: color 0.5s; /* For modern browsers */
}

.taskActionEditIcon:hover {
color: red;
}
.MuiTypography-body1 {
	font-family: inherit !important;
}
.MuiTypography-body2 {
	font-family: inherit !important;
}

.dashboard-widget {
	background: white;
	text-align: right;
}
.dashboard-widget .dashboard-widget-content {
	padding: 10px;
}
.dashboard-widget .title {
	margin: 0;
}
.dashboard-widget .subtitle {
	font-size: 8px;
}

.bar-chart {
	background: #90daab;
  height: 20px;
}

.bar-chart-percentage {
	background-color: #5b907a;
	width: 0;
	height: 20px;
	float: right;
	color: white;
	font-size: 10px;
	font-weight: bold;
}
.bar-chart-percentage-text {
	margin-right: 10px;
}
.bullet-select {
	font-size: 1.25rem !important;
  margin: 0 0 1.5rem 0;
	font-family: "Source Sans Pro", Helvetica, sans-serif !important;
	font-weight: 900 !important;
	line-height: 1.5 !important;
	letter-spacing: 0.075em !important;
	text-transform: uppercase !important;
}
.bullet-select:after {
	border-bottom: 0px !important;
}

.graph-widget {
	background: white;
}

.graph-widget h4 {
	padding-top: 10px;
	padding-left: 40px;
}

#footer {
	bottom: 0;
	position: fixed;
	width: 100%;
	font-size: 10px;
	background-color: #c3bfac;
	right: 0;
	padding: 0 10px;
	text-align: right;
}

.MuiTimelineDot-filled {
	box-shadow: none;
}

.MuiTimelineDot-filled {
	background: white;
}

.MuiTimelineDot-filledInfo {
	background: #0288d1;
}

.MuiTimelineConnector-root {
	background-color: white;
}

.MuiTablePagination-toolbar .MuiTablePagination-selectLabel,
.MuiTablePagination-toolbar .MuiTablePagination-displayedRows {
	margin-bottom: 0;
}

.MuiTimeline-root .MuiTimelineContent-positionRight {
    word-break: break-word;
}

#bullet-log-container p {
	margin-bottom: 0;
}

#bullet-log-container span[contenteditable="false"] {
	margin-top: -10px;
	font-style: italic;
}

#app-bar {
	border-bottom: 2px solid #ffffff40;
	background-color: #ffffff2e;
}
.MuiDialogTitle-root {
	text-transform: uppercase;
}

.habitsWidget .MuiList-root {
	background-color: #ffffffad;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5d8d7;
}

/* Handle */
::-webkit-scrollbar-thumb:hover {
  background: #4c8869;
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
  background: #945e5e;
}

.habitsWidget ::-webkit-scrollbar-thumb:hover {
  background: #945e5e;
}

.habitsWidget ::-webkit-scrollbar-thumb {
  background: #4c8869;
}

#day-planner-container ::-webkit-scrollbar-thumb:hover {
  /* background: #945e5e; */
	background: #4c886985;
}

#day-planner-container ::-webkit-scrollbar-thumb {
  /* background: #4c886938; */
	background: transparent;
}

.taskCard {
	padding: 5px 0;
}

.subtask .MuiFormControlLabel-label {
	width: 80%;
	font-size: 12px;
}

.subtask .MuiInputBase-formControl {
	font-size: 12px;
	font-family: "Merriweather", Georgia, serif;
}